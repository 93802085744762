import { Grid } from '@audentio/stuff/src';
import { Layout } from 'components/Layout';
import { PrincipleBenefits } from 'components/Principle/Benefits';
import { PrincipleFeatures } from 'components/Principle/Features';
import { PrinciplesNav } from 'components/PrinciplesNav';
import { graphql } from 'gatsby';
import React from 'react';
import { AvatarHighlight } from 'src/components/AvatarHighlight';
import { LandingFeatureGrid } from 'src/components/Landing/LandingFeatureGrid';
import {
    ClientHighlight,
    StatBox
} from 'src/components/Methods/metrics/ClientHighlight';
import { FeatureHighlight } from 'src/components/Principle/FeatureHighlight';
import { PrincipleHero } from 'src/components/Principle/Hero';

export default function WellnessPage({ data }) {
    return (
        <Layout
            pageTitleFull="Wellness: An Audentio Community Principle"
            siteDescription="Create the right culture for your online community with guidance and support from community strategists."
            contactForm
            subnavTitle="Wellness"
            subnav={[
                {
                    id: 'features',
                    label: 'Features',
                },
                {
                    id: 'moderation',
                    label: 'Moderation',
                },
                {
                    id: 'benefits',
                    label: 'Benefits',
                },
            ]}
        >
            <PrincipleHero
                title="A fair and transparent community that thrives"
                subtitle="Wellness"
                image={data.hero.childImageSharp.fluid}
            />

            <PrinciplesNav name="wellness" />

            <PrincipleFeatures
                id="features"
                title="build a good culture around your community"
                features={[
                    {
                        subtitle: 'moderation',
                        title: 'be involved',
                        content:
                            "Having the community leaders actively and regularly involved helps energize the community, add to the culture, keep leadership in touch with the community's needs.",
                    },
                    {
                        subtitle: 'support',
                        title: 'host frequent webinars',
                        content:
                            "Keeping up to date with the latest changes and happenings in the community is a big job. Invite your members to a discussion on what's new, what's coming soon, and their opinions and ideas.",
                    },
                    {
                        subtitle: 'training',
                        title: 'train admins & moderators',
                        content:
                            "Your community staff is a big part of what shapes your community's interaction. Train them on tools and techniques to make them the best.",
                    },
                    {
                        subtitle: 'moderation',
                        title: 'better monitor toxic content',
                        content:
                            'Keeping an eye out on an entire community for undesirable content requires a lot of work. Use tools where you can to make it easier.',
                    },
                    {
                        subtitle: 'support',
                        title: 'communicate guidelines and rules',
                        content:
                            'Help everybody play by the rules by showcasing them in a clear, effective manner and follow through on the expectations you set.',
                    },
                    {
                        subtitle: 'gaming',
                        title: 'incentivize positive activity',
                        content:
                            "Everybody likes cookies, right? These aren't participation trophies, but actual methods to reward and encourage positive behavior in your community.",
                    },
                ]}
            />

            {/* <CTA
                title="Ready to build a community?"
                buttonText="Contact us"
                href="/contact?source=wellness"
            /> */}

            <FeatureHighlight
                id="moderation"
                bg="secondaryBackground"
                subtitle="Moderation"
                py={['40px', 'spacing-xl', '80px']}
                my={0}
                image={data.wellness.childImageSharp.fluid}
                title="Thoughtful communication is key for a positive and productive community culture"
                // video={
                //     <video
                //         onClick={e => {
                //             document.getElementById('tone-detect-video').play();
                //         }}
                //         muted
                //         autoPlay
                //         id="tone-detect-video"
                //         poster={data.hero.childImageSharp.publicURL}
                //         loop
                //     >
                //         <source
                //             src="/videos/tone-detect.mp4"
                //             type="video/mp4"
                //         />
                //     </video>
                // }
                highlights={[
                    {
                        title: 'set the stage',
                        content:
                            'Clearly establish expectations and have your team lead by example.',
                    },
                    {
                        title: 'reduce toxicity',
                        content:
                            'Utilize tools to discourage negative content and behavior.',
                    },
                    {
                        title: 'support and educate your team',
                        content:
                            'Stay in communication and share community management best practices.',
                    },
                ]}
            />

            <AvatarHighlight
                image={data.webinar.childImageSharp.fluid}
                avatar={data.webinarAvatar.childImageSharp.fluid}
                content="We have the most badass team of all time, team Audentio. Sarah,
                            Ian, Alex, Mike, and others. These people are unbelievable, they
                            are community gurus and they’re getting started with the
                            greatest tools on planet earth for you guys to communicate with
                            each other, to help each other, to benefit from whatever
                            experiences you’re having on the site."
                cite="Brian Haner jr. Synyster Gates School"
                title="Webinars allow you to directly connect with your community"
                border
                bg="primaryBackground"
                borderProps={{
                    h: '1px',
                    w: '40',
                    bg: 'gray.300',
                    mt: 'spacing-lg',
                    mb: 'spacing-lg',
                }}
            />
            <PrincipleBenefits
                id="benefits"
                title="How wellness can benefit your community"
                // ctaHref="/contact?source=wellness"
                // ctaLabel="Contact us"
                mt={0}
                benefits={[
                    'promote a positive culture',
                    'build trust between the managers and community',
                    'better alert administrators to activity requiring attention',
                ]}
            />

            <ClientHighlight
                title="House Cup, Slack and Discord bot"
                image={data.housecupHero.childImageSharp.fluid}
                description="Our team wanted to find a way to recognize each other more and enhance our overall culture. So we decided to gamify it and made House Cup - a simple way for people to award (or remove) points to each other."
                link="https://www.audent.io/case-study/housecup/full"
                linkText="View Case Study"
                pt={[0, 0, 0]}
                alt="Slack image showing the housecup bot in action"
                gridSectionProps={{ borderTop: '0px' }}
            >
                <Grid
                    columns={{
                        _: 2,
                        lg: 1,
                    }}
                    spacing={{
                        _: 'spacing-lg',
                        lg: 'spacing-xl',
                    }}
                    w={{
                        _: '100%',
                        lg: 250,
                    }}
                >
                    <StatBox title="219k" subtitle="Users" kind="onboarded" />
                    <StatBox title="192k" subtitle="Messages" kind="shared" />
                </Grid>
            </ClientHighlight>

            <LandingFeatureGrid
                center
                offset={false}
                flexDirection="column"
                title="Related articles"
                sectionHeaderProps={{
                    direction: 'row',
                }}
                customButton={{
                    text: 'See More',
                    href: 'https://adjourn.audent.io/',
                }}
                featureGridProps={{
                    mx: 0,
                    px: 0,
                    columns: [1, 2, null, 4],
                    spacing: {
                        _: 'spacing-lg',
                    },
                }}
                showContact={false}
                items={[
                    {
                        image: data.uniquePositive.childImageSharp.fluid,
                        heading:
                            'Creating a unique and positive identity for your community',
                        content:
                            'Building a community requires a significant investment, and not just from you. As a community manager, you’re asking people to join, share...',
                        href:
                            'https://adjourn.audent.io/creating-a-unique-and-positive-identity-for-your-community-84b5f42034c7',
                    },
                    {
                        image: data.creatingWellness.childImageSharp.fluid,
                        heading: 'Creating wellness in online communities',
                        content:
                            'Building a good culture takes some ingenuity, the right attitude, and a lot of work. Here are some thoughts on how to encourage wellness.',
                        href:
                            'https://adjourn.audent.io/creating-wellness-in-online-communities-f3d31e1c9924',
                    },
                    {
                        image: data.introduction.childImageSharp.fluid,
                        heading:
                            'Why an introduction forum is important in your community',
                        content:
                            'Ever enter a room full of people you don’t know? It can feel intimidating at times. Joining a digital community can have a similar feeling, so making...',
                        href:
                            'https://adjourn.audent.io/why-an-introduction-forum-is-important-in-your-community-902a4136a8c9',
                    },
                    {
                        image: data.successfulCommunity.childImageSharp.fluid,
                        heading:
                            'What makes for a successful upgrade, migration, or big change to your community',
                        content:
                            'Change can be tough for many members of your community. Having the right strategy in place will help with the overall transition and.',
                        href:
                            'https://adjourn.audent.io/what-makes-for-a-successful-upgrade-migration-or-big-change-to-your-community-897aa44d3df7',
                    },
                ]}
            />
        </Layout>
    );
}

export const query = graphql`
    query WellnessQuery {
        hero: file(relativePath: { eq: "images/hero/wellness.jpg" }) {
            publicURL
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        webinar: file(
            relativePath: { eq: "images/wellness/webinar-image.png" }
        ) {
            publicURL
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        webinarAvatar: file(
            relativePath: { eq: "images/wellness/brian-avatar.png" }
        ) {
            publicURL
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        successfulCommunity: file(
            relativePath: { eq: "images/wellness/successful-community.png" }
        ) {
            publicURL
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        uniquePositive: file(
            relativePath: { eq: "images/wellness/unique-positive.png" }
        ) {
            publicURL
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        introduction: file(
            relativePath: { eq: "images/wellness/introduction.png" }
        ) {
            publicURL
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        creatingWellness: file(
            relativePath: { eq: "images/wellness/creating-wellness.png" }
        ) {
            publicURL
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        wellness: file(relativePath: { eq: "images/wellness/wellness.png" }) {
            publicURL
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        housecupHero: file(
            relativePath: { eq: "images/wellness/housecup-hero.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
